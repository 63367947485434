@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&family=Roboto&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  background-color: #f8f8f8; }

body {
  box-sizing: border-box; }

h1,
h2,
h3,
h4 {
  font-family: "Barlow Condensed", sans-serif;
  text-transform: uppercase; }

h1 {
  font-size: 5rem; }

h2 {
  font-size: 3rem; }

h3 {
  font-size: 1.5rem; }

p,
label {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem; }

a {
  text-decoration: none;
  font-weight: 600;
  color: #11998e; }
  a:visited {
    color: #11998e; }

.source {
  text-align: center;
  margin: 3rem 0; }

.footer {
  text-align: center; }

.header {
  padding: 10rem 5rem;
  margin-bottom: 2rem;
  text-align: center;
  background-color: #192a56;
  width: 100%;
  position: relative;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("assets/earth.png");
  color: #e5e5e5; }
  .header .title {
    font-size: 7rem;
    margin-bottom: 5rem;
    text-shadow: 2px 2px #000; }
  .header .attribution {
    position: absolute;
    right: 20px;
    top: 0;
    padding: 1rem; }

.container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  margin: 0; }
  @media (min-width: 43rem) {
    .container {
      padding: 5rem 20rem; } }
  @media (min-width: 56rem) {
    .container {
      padding: 5rem 20rem; } }
  .container .source {
    display: block;
    text-align: center; }

.cards {
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  width: 100%; }
  @media (min-width: 43rem) {
    .cards {
      width: 50%; } }
  @media (min-width: 56rem) {
    .cards {
      width: 33.3%; } }
  .cards .card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    background: #fff;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 2rem;
    margin: 1rem;
    overflow: hidden;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s; }
    .cards .card:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.3s ease; }
    .cards .card .recyclable {
      padding: 2rem; }
      .cards .card .recyclable .recyclable-text {
        font-size: 5rem;
        text-transform: uppercase;
        text-align: center; }
      .cards .card .recyclable .positive {
        color: #5a8b44; }
      .cards .card .recyclable .negative {
        color: #ea3f3f; }
    .cards .card .category {
      color: #11998e; }
    .cards .card .margin {
      margin-left: 0.5rem; }
    .cards .card .item-name {
      padding-bottom: 2rem; }
    .cards .card .details {
      text-align: center; }
    .cards .card .details-text {
      color: grey;
      font-size: 1.2rem; }

.search {
  border: 2px solid grey;
  border-radius: 3px;
  background: #fff;
  min-height: 50px;
  width: 100%;
  padding: 5px;
  outline: none;
  max-width: 500px;
  font-size: 2rem;
  color: #11998E; }
  .search:focus {
    outline: none; }
